import React, {useRef} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {BrowserView, MobileView} from 'react-device-detect';
import {ControlButton, TscButtonType, TscStyles, TscThemeName, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import {ButtonStyledAsAnchor} from '../../util/StyledElements';
import DropMediaGlyph from '../../../static/svg/UploadDropMediaGlyph.svg';
import WarningGlyph from '../../../static/svg/Warning20x20.svg';
import RocketGlyph from '../../../static/svg/Rocket20x20.svg';
import accepts from 'attr-accept';
import '../../../static/css/modal.less';
import WarningWithFaqLink from '../../util/WarningWithFaqLink';
import {
   FileUploadStates,
   IFileUploadCandidate,
   MediaTypes,
   nullFileUploadCandidate
} from '../../../model/fileUploadCandidate';
import withMemoizedContexts from '../../../context/contextContainerHoC';
import {themeStore} from '../../../context/themeProvider';
import {userQuotaStore} from '../../../context/userQuotaProvider';
import Constants from '../../../constants/Constants';
import CssUtil from '../../../util/CssUtil';
import config from '../../../service/config';

// eslint-disable-next-line no-magic-numbers
const MAX_FILE_SIZE = 4 * 1024 * 1024 * 1024; // 4GB
export const ACCEPTED_IMAGE_FILE_TYPE = 'image/jpeg,image/png,image/gif';
export const ACCEPTED_VIDEO_AND_IMAGE_FILE_TYPE = 'video/mp4,video/quicktime,video/mov,video/f4v,video/x-f4v,image/jpeg,image/png,image/gif';
export const ACCEPTED_AUDIO_FILE_TYPE = 'audio/mp3,audio/x-mp3,audio/mpeg3,audio/x-mpeg-3,audio/mpeg,audio/x-mpeg,audio/m4a,audio/x-m4a,audio/mp4';
export const ACCEPTED_LOGO_FILE_TYPE = 'image/png';

const quotaWarningDawnBorderColor = '#e1b046';
const quotaWarningDawnBackgroundColor = '#fcf7ea';
const quotaWarningDuskBackgroundOpacity = .1;

enum DropZoneStateType {
   normal= 'normal',
   acceptedFile= 'accepted-file',
   rejectedFile= 'rejected-file',
   rejectedFileExceedQuota= 'rejected-file-exceed-quota',
   ieOrSafariBrowser= 'ie-or-safari-browser',
   unknownFile= 'unknown-file'
}

const DropZone = styled.div<{theme: TscThemeNames}>`
   border: 2px dashed ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.dark : TscStyles.color.ui.dawn.mediumDark};
   border-radius: ${TscStyles.border.radius.lg};
   padding: 1.5rem 0;
   margin-bottom: 2rem;
   text-align: center;
   background-color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.ui.dusk.mediumDark : TscStyles.color.ui.dawn.lightMedium};
   cursor: pointer;
`;

const SvgWrapper = styled.div`
   height: 9rem;

   >svg {
      transform: scale(2);
      transform-origin: top;
   }
`;

const HiddenFileInput = styled.input`
   width: 0.1px;
   height: 0.1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
`;

const HiddenFileInputLabel = styled.label`
   display: none;
`;

const FileSelectButton = styled(ButtonStyledAsAnchor)`
   margin-left: 0.25rem;
`;

export const QuotaUploadWarningWrapper = styled.div<{theme: TscThemeNames}>`
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   white-space: pre-wrap;
   height: 3.25rem;
   padding: 0 .5rem 0 0;
   color: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.light : TscStyles.color.text.mediumDark};
   border: 1px ${props => props.theme === TscThemeName.dusk ? TscStyles.color.brand.screencast : quotaWarningDawnBorderColor} solid;
   border-radius: ${TscStyles.border.radius.lg};
   background-color: ${props => props.theme === TscThemeName.dusk ? CssUtil.toRgba(TscStyles.color.brand.screencast, quotaWarningDuskBackgroundOpacity) : quotaWarningDawnBackgroundColor};
   margin-bottom: .75rem;

   a {
      margin-bottom: 0!important;
   }
`;

const QuotaSvgGlyphs = styled.div<{theme: TscThemeNames}>`
   display: flex;
   flex: 0 0 auto;
   justify-content: center;
   align-items: center;
   min-width: 3.25rem;

   svg {
      fill: ${props => props.theme === TscThemeName.dusk ? TscStyles.color.text.light : TscStyles.color.text.mediumDark};
   }
`;

const QuotaUploadWarningText = styled.div`
   flex: 1 1 auto;
   font-size: .75rem;
   font-weight: 600;
   padding-right: .5rem;
`;

const QuotaUploadWarningTextTitle = styled.p`
   font-size: .875rem;
   margin: 0;
   padding: 0;
`;

const MobileUploadButtonContainer = styled.div`
   .mobile-upload-button {
      padding: 0 32px;
   }
`;

export const LocalMediaImporterBase: React.FC<ILocalMediaImporterProps & IStateMappedProps> = ({allowMultiple, ignoreQuotaLimits, isAudioUploader, isLogoUploader, onFileSelect, CurrentVideoCount, VideoCountLimit, VideoCountExceeded, UserHasQuotaInformation, theme}) => {
   const {t} = useTranslation();
   const [state, setState] = React.useState({
      dropZoneState: DropZoneStateType.normal
   } as ILocalMediaImporterState);
   const filePickerRef = useRef<HTMLInputElement>(null);
   const blockOnError = !allowMultiple || UserHasQuotaInformation;

   const getAcceptedFileTypes = () => {
      if (isLogoUploader) {
         return ACCEPTED_LOGO_FILE_TYPE;
      }
      if (isAudioUploader) {
         return ACCEPTED_AUDIO_FILE_TYPE;
      }
      return !VideoCountExceeded || ignoreQuotaLimits ? ACCEPTED_VIDEO_AND_IMAGE_FILE_TYPE : ACCEPTED_IMAGE_FILE_TYPE;
   };

   const filesExceedQuota = (files: IFileUploadCandidate[]): boolean => {
      if (!UserHasQuotaInformation || ignoreQuotaLimits) {
         return false;
      }

      const videoFiles = files.filter((file: IFileUploadCandidate) => file.mediaType === MediaTypes.video);
      return CurrentVideoCount + videoFiles.length > VideoCountLimit;
   };

   const onFilePickerChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const decoratedFiles = decorateFiles(Array.from(event.target.files));
      if (filesExceedQuota(decoratedFiles)) {
         setState({...state, dropZoneState: DropZoneStateType.rejectedFileExceedQuota});
         return;
      }

      if (blockOnError && !filesShouldBeAccepted(decoratedFiles)) {
         setState({...state, dropZoneState: DropZoneStateType.rejectedFile});
         return;
      }

      // this resets the hidden input value so if you select (not drag-drop) the same file(s) twice in a row, it will trigger
      if (filePickerRef && filePickerRef.current) {
         filePickerRef.current.value = '';
      }
      returnFiles(decoratedFiles);
   };

   const returnFiles = (files: IFileUploadCandidate[]): void => {
      onFileSelect(allowMultiple ? files : files[0]);
   };

   const getFileState = (file: File): FileUploadStates => {
      if (file.type === 'application/x-moz-file') {
         return FileUploadStates.unknownType;
      } else if (!accepts(file, getAcceptedFileTypes())) {
         return FileUploadStates.rejectedType;
      } else if (file.size > MAX_FILE_SIZE) {
         return FileUploadStates.fileSizeError;
      }
      return FileUploadStates.ready;
   };

   const decorateFiles = (files: File[]): IFileUploadCandidate[] => files.map(file => nullFileUploadCandidate({
      file: file,
      mediaType: file.type.includes('video') ? MediaTypes.video :
         file.type.includes('image') ? MediaTypes.image :
            file.type.includes('audio') ? MediaTypes.audio :
               MediaTypes.unknown,
      state: getFileState(file)
   }));

   const filesShouldBeAccepted = (files: IFileUploadCandidate[]): boolean => {
      if (!allowMultiple && files.length > 1) {
         return false;
      }

      return files.every(file => file.state === FileUploadStates.ready);
   };

   const areUnknownFiles = (files: IFileUploadCandidate[]): boolean => {
      if (!files) {
         return true;
      }

      return files.some(file => file.state === FileUploadStates.unknownType);
   };

   const onDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
      e.preventDefault();
      // Drag enter event does not have access to actual files yet, but can detect file types from the dataTransfer.items list
      const draggedFilesPreview = decorateFiles(Array.from(e?.dataTransfer?.items).map((item: DataTransferItem) => new File([], 'file-type-preview', {type: item.type})));
      let newDropZoneState: string;

      if (filesExceedQuota(draggedFilesPreview)) {
         newDropZoneState = DropZoneStateType.rejectedFileExceedQuota;
         setState({...state, dropZoneState: newDropZoneState});
         return;
      }

      if (e?.dataTransfer?.items.length === 0) {
         newDropZoneState = DropZoneStateType.ieOrSafariBrowser;
         setState({...state, dropZoneState: newDropZoneState});
         return;
      }

      if (blockOnError) {
         if (areUnknownFiles(draggedFilesPreview)) {
            newDropZoneState = DropZoneStateType.unknownFile;
         } else {
            newDropZoneState = filesShouldBeAccepted(draggedFilesPreview) ? DropZoneStateType.acceptedFile : DropZoneStateType.rejectedFile;
         }

         setState({...state, dropZoneState: newDropZoneState});
      }
   };

   const onDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
      e.preventDefault();
   };

   const onDrop = (e: React.DragEvent<HTMLDivElement>): void => {
      e.preventDefault();
      const droppedFiles = decorateFiles(Array.from(e.dataTransfer.files));

      if (blockOnError) {
         const droppedFilesExceedQuota = filesExceedQuota(droppedFiles);
         let newDropZoneState = droppedFilesExceedQuota ? DropZoneStateType.rejectedFileExceedQuota : DropZoneStateType.acceptedFile;

         if (newDropZoneState === DropZoneStateType.acceptedFile) {
            if (areUnknownFiles(droppedFiles)) {
               newDropZoneState = DropZoneStateType.unknownFile;
            } else {
               newDropZoneState = filesShouldBeAccepted(droppedFiles) ? DropZoneStateType.acceptedFile : DropZoneStateType.rejectedFile;
            }
         }

         if (newDropZoneState === DropZoneStateType.acceptedFile) {
            setState({...state, dropZoneState: DropZoneStateType.normal});
         } else {
            setState({...state, dropZoneState: newDropZoneState});
            return;
         }
      }

      returnFiles(droppedFiles);
   };

   const onDragOver = (e: React.DragEvent<HTMLDivElement>): boolean => {
      e.preventDefault();
      e.stopPropagation();
      try {
         e.dataTransfer.dropEffect = 'copy';
      } catch (err) {
         // oh well
      }
      return false;
   };

   const simulateFilePickerClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setState({...state, dropZoneState: DropZoneStateType.normal});
      filePickerRef.current.click();
   };

   const getWarningText = (): string => {
      if (isAudioUploader) {
         return t('uploadMedia.fileTypeWarningAudio');
      } else if (isLogoUploader) {
         return t('uploadMedia.fileTypeWarningCustomLogo');
      } else if (allowMultiple) {
         return t('uploadMedia.fileTypeWarningVideoMultiple');
      }
      return t('uploadMedia.fileTypeWarningVideo');
   };

   const getWarningWithFaqLinkUrl = (): string => {
      if (isLogoUploader) {
         return Constants.navigation.customLogoMediaFAQLink;
      } else if (config.featureSwitches.IsScreencast) {
         return Constants.navigation.screencastFAQLink;
      }
      return Constants.navigation.knowmiaFAQLink;
   };

   const getWarningMessage = (): React.JSX.Element => {
      if (VideoCountExceeded) {
         return (
            <QuotaUploadWarningWrapper theme={theme}>
               <QuotaSvgGlyphs theme={theme}>
                  <RocketGlyph />
               </QuotaSvgGlyphs>
               <QuotaUploadWarningText>
                  <QuotaUploadWarningTextTitle>{t('uploadMedia.fileTypeWarningAtQuota.title')}</QuotaUploadWarningTextTitle>
                  {t('uploadMedia.fileTypeWarningAtQuota.body')}
               </QuotaUploadWarningText>
               <ControlButton buttonType={TscButtonType.primaryScreencast} label={t('uploadMedia.fileTypeWarningExceededQuotaActionButtonText')} openLinkInNewTab={true} href={config.environmentData.ScreencastProPurchaseLinkUrl} />
            </QuotaUploadWarningWrapper>
         );
      } else if (state.dropZoneState === DropZoneStateType.rejectedFileExceedQuota) {
         return (
            <QuotaUploadWarningWrapper theme={theme}>
               <QuotaSvgGlyphs theme={theme}>
                  <WarningGlyph />
               </QuotaSvgGlyphs>
               <QuotaUploadWarningText>{t('uploadMedia.fileTypeWarningExceededQuota')}</QuotaUploadWarningText>
               <ControlButton buttonType={TscButtonType.primaryScreencast} label={t('uploadMedia.fileTypeWarningExceededQuotaActionButtonText')} openLinkInNewTab={true} href={config.environmentData.ScreencastProPurchaseLinkUrl} />
            </QuotaUploadWarningWrapper>
         );
      }

      return (
         <WarningWithFaqLink linkUrl={getWarningWithFaqLinkUrl()}>
            {getWarningText()}
         </WarningWithFaqLink>
      );
   };

   const displayWarning = (VideoCountExceeded && !ignoreQuotaLimits) || state.dropZoneState !== DropZoneStateType.normal && state.dropZoneState !== DropZoneStateType.acceptedFile && state.dropZoneState !== DropZoneStateType.ieOrSafariBrowser;

   return (
      <div className="themeable-section" data-test-id="local-media-importer" id={'local-media-importer'}>
         {displayWarning && getWarningMessage()}
         <DropZone
            theme={theme}
            onClick={simulateFilePickerClick}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            data-test-id="media-import-drop-zone"
         >
            <SvgWrapper>
               <DropMediaGlyph />
            </SvgWrapper>
            <span>
               <BrowserView>
                  <Trans i18nKey="uploadMedia.getFile">
                     Drag and drop or
                     <FileSelectButton onClick={simulateFilePickerClick}>
                        browse for file
                     </FileSelectButton>
                  </Trans>
               </BrowserView>
               <MobileView>
                  <MobileUploadButtonContainer>
                     <ControlButton className={'mobile-upload-button'} themeName={theme} buttonType={TscButtonType.secondary} label={t('uploadMedia.browseForFile')} onClick={simulateFilePickerClick} />
                  </MobileUploadButtonContainer>
               </MobileView>
            </span>
         </DropZone>
         <HiddenFileInput
            type="file"
            multiple={allowMultiple}
            name="file"
            id="local-importer-file-selector"
            aria-label={t('uploadMedia.browseForFile')}
            onChange={onFilePickerChange}
            accept={getAcceptedFileTypes()}
            ref={filePickerRef}
            tabIndex={-1}
         />
         <HiddenFileInputLabel htmlFor="local-importer-file-selector">
            {t('uploadMedia.browseForFile')}
         </HiddenFileInputLabel>
      </div>
   );
};

export interface ILocalMediaImporterProps {
   allowMultiple: boolean;
   ignoreQuotaLimits?: boolean;
   isAudioUploader?: boolean;
   isLogoUploader?: boolean;
   onFileSelect(file: IFileUploadCandidate | IFileUploadCandidate[]): void;
}

export interface ILocalMediaImporterState {
   dropZoneState: string;
}

export interface IStateMappedProps {
   CurrentVideoCount: number;
   VideoCountLimit: number;
   VideoCountExceeded: boolean;
   UserHasQuotaInformation: boolean;
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {allowMultiple, ignoreQuotaLimits, isAudioUploader, isLogoUploader, onFileSelect}: ILocalMediaImporterProps,
   {CurrentVideoCount, VideoCountLimit, VideoCountExceeded, UserHasQuotaInformation}: Partial<IStateMappedProps>,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & ILocalMediaImporterProps => ({allowMultiple, ignoreQuotaLimits, isAudioUploader, isLogoUploader, onFileSelect, CurrentVideoCount, VideoCountLimit, VideoCountExceeded, UserHasQuotaInformation, theme});

export default withMemoizedContexts(mapStatesToProps, userQuotaStore, themeStore)(LocalMediaImporterBase);
