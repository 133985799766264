import React from 'react';
import {ControlButton, TscButtonType, Glyphs, TscThemeName, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import styled from 'styled-components';
import SidebarContent from './SidebarContent';
import SiteLogo from '../header/SiteLogo';
import {INavigationTab} from '../tabs/Tabs';
import {useTranslation} from 'react-i18next';
import config from '../../service/config';
import MobileNavFooterLogo from '../../static/svg/MobileNavFooterLogo.svg';
import CreateMediaContent from './CreateMediaContent';
import cssConstants from '../../constants/cssConstants';
import UserQuotas from '../header/UserQuotas';

export const SideBarContainer = styled.div<{width: string}>`
   background: ${TscStyles.color.ui.dusk.mediumDark};
   height: 100%;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   text-align: left;
   color-scheme: dark;
   width: ${props => props.width || cssConstants.sidebarWidth};
   min-width: ${props => props.width || cssConstants.sidebarWidth};
`;

const MenuHeader = styled.div`
   display: flex;
   flex-direction: row;
   flex: none;
   justify-content: space-between;
   height: 3.25rem;
   align-items: center;
   background: ${cssConstants.sidebarAccentColor};

   a {
      margin-left: 1rem;
      max-width: 12rem;
      overflow: visible;
      align-items: center;

      svg {
         fill: ${TscStyles.color.text.light};
      }
   }

   button {
      margin-right: 1rem;
      fill: ${TscStyles.color.ui.dawn.medium};
   }
`;

export const MenuContentWrapper = styled.ul`
   flex: 1 1 auto;
   overflow-y: auto;
   overflow-x: hidden;
   display: flex;
   flex-direction: column;
   margin: 0;
   padding: 0;
`;

const MenuFooter = styled.div<{displayLogoLink: boolean}>`
   display: block;
   box-sizing: border-box;
   padding: ${props => props.displayLogoLink ? '1rem' : '.5rem'};
   border-top: 1px solid ${cssConstants.sidebarAccentColor};
`;

const FooterLogoLink = styled.a`
   display: flex;

   svg {
      height: 1rem;
   }
`;

const Sidebar: React.FC<ISidebarProps> = ({tabs, displayAddMediaContent, width, toggleMenuIsHidden, onMenuContentClick, menuIsHidden}) => {
   const {t} = useTranslation();

   return (
      <SideBarContainer width={width}>
         {toggleMenuIsHidden && <MenuHeader>
            <SiteLogo isSideBar={true} />
            <ControlButton
               buttonType={TscButtonType.tertiary}
               glyph={<Glyphs.Close16x16 />}
               themeName={TscThemeName.dusk}
               onClick={toggleMenuIsHidden}
               title={t('CloseMenu')}
               testId="sidebar-close-menu-button"
            />
         </MenuHeader>}
         {displayAddMediaContent && <CreateMediaContent/>}
         <MenuContentWrapper onClick={onMenuContentClick}>
            {!menuIsHidden && <SidebarContent tabs={tabs} />}
         </MenuContentWrapper>
         <MenuFooter displayLogoLink={!config.featureSwitches.IsScreencast}>
            {config.featureSwitches.IsScreencast ? <UserQuotas theme={TscThemeName.dusk} /> :
               <FooterLogoLink href={config.sidebarLogoLinkUrl} title={config.applicationFullName} target="_blank" rel="noopener noreferrer nofollow">
                  <MobileNavFooterLogo />
               </FooterLogoLink>
            }
         </MenuFooter>
      </SideBarContainer>
   );
};

export interface ISidebarProps {
   tabs: INavigationTab[];
   displayAddMediaContent?: boolean;
   width?: string;
   menuIsHidden: boolean;
   toggleMenuIsHidden?(): void;
   onMenuContentClick?(e: React.MouseEvent): void;
}

export default Sidebar;
