import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Glyphs, MenuItem} from '@techsmith/tsc-cloud-style-guide';
import CreateOrRenameFolderModal from '../modals/CreateFolderModal';
import withMemoizedContexts from '../../context/contextContainerHoC';
import RenameTeamModal from '../modals/RenameTeamModal';
import LeaveTeamModal from '../modals/LeaveTeamModal';
import DeleteTeamModal from '../modals/DeleteTeamModal';
import {ITeamModel} from '../../model/appModel';
import Constants, {collectionRoleTypeAsInteger, GroupRoleTypes} from '../../constants/Constants';
import {teamsStore} from '../../context/teamsProvider';
import TeamLeaveGlyph from '../../static/svg/leave-16x16.svg';

export const TeamMoreMenuBase: React.FC<ITeamMoreMenuProps & IStateMappedProps> = ({team, activeTeam}) => {
   const {t} = useTranslation();
   const [showRenameModal, setShowRenameModal] = useState(false);
   const [showLeaveModal, setShowLeaveModal] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [showNewFolderModal, setShowNewFolderModal] = useState(false);

   const deleteTeamOnClose = () => {
      setShowDeleteModal(false);
   };

   const redirectToTeamsList = () => {
      if (activeTeam.TeamId === team.TeamId) {
         window.location.href = Constants.navigation.teamsLink;
      }
   };

   return (<>
      {collectionRoleTypeAsInteger(team.Role) >= collectionRoleTypeAsInteger(GroupRoleTypes.contributor) && <MenuItem key="rename" label={t('team.options.rename')} glyph={<Glyphs.Edit16x16/>} onClick={() => setShowRenameModal(true)} testId={'teams-actions-more-rename'} />}
      {team.Role === GroupRoleTypes.manager ?
         <MenuItem key="delete" label={t('team.options.delete')} glyph={<Glyphs.Delete16x16/>} onClick={() => setShowDeleteModal(true)} testId={'teams-actions-more-delete'} />
         : <MenuItem key="leave" label={t('team.options.leave')} glyph={<TeamLeaveGlyph/>} onClick={() => setShowLeaveModal(true)} testId={'teams-actions-more-leave'} />}
      {collectionRoleTypeAsInteger(team.Role) >= collectionRoleTypeAsInteger(GroupRoleTypes.contributor) && <MenuItem key="newFolder" label={t('team.options.addFolder')} glyph={<Glyphs.FolderAdd16x16/>} onClick={() => setShowNewFolderModal(true)} testId={'teams-actions-new-folder'} />}

      {showRenameModal && <RenameTeamModal onClose={() => setShowRenameModal(false)} team={team} />}
      {showLeaveModal && <LeaveTeamModal team={team} onClose={() => setShowLeaveModal(false)} onLeave={redirectToTeamsList} />}
      {showDeleteModal && <DeleteTeamModal team={team} onClose={deleteTeamOnClose} onDelete={redirectToTeamsList} />}
      {showNewFolderModal && <CreateOrRenameFolderModal onClose={() => setShowNewFolderModal(false)} teamId={team.TeamId} />}
   </>);
};

export interface ITeamMoreMenuProps {
   team: ITeamModel;
}

export interface IStateMappedProps {
   activeTeam: ITeamModel;
}

const mapStatesToProps = (
   {team}: ITeamMoreMenuProps,
   {activeTeam}: IStateMappedProps
): ITeamMoreMenuProps & IStateMappedProps => ({team, activeTeam});

export default withMemoizedContexts(mapStatesToProps, teamsStore)(TeamMoreMenuBase);
