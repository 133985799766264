import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ControlButton, TscButtonType, TextInput, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';
import {MinWidthButtonWrapper, StyledWarning} from '../util/StyledElements';
import '../../static/css/modal.less';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import {IWorkerState} from '../../model/workerState';
import {ITeamModel} from '../../model/appModel';
import Constants from '../../constants/Constants';
import StringUtil from '../../util/StringUtil';
import teamApi from '../../service/teamApi';
import {
   renameTeam as renameTeamAction
} from '../../context/teamsProvider';

interface IModalState extends IWorkerState {
   teamName: string;
   errorMessage: string;
}

export const RenameTeamModalBase: React.FC<IRenameTeamModalProps & IStateMappedProps> = ({onClose, theme, team}) => {
   const {t} = useTranslation();
   const [modalState, updateModalState] = useState<IModalState>({
      isWorking: false,
      hasError: false,
      teamName: team.Name,
      errorMessage: ''
   });

   const updateTeamName = (evt: React.ChangeEvent<HTMLInputElement>): void => {
      const updatedTeamName = evt.target.value;

      updateModalState({
         ...modalState,
         teamName: updatedTeamName,
         hasError: updatedTeamName.length > Constants.maxCollectionNameLength,
         errorMessage: updatedTeamName.length > Constants.maxCollectionNameLength ? t('collection.modal.rename.errorNameTooLong', {maxLength: Constants.maxCollectionNameLength}) : ''
      });
   };

   const renameTeam = async () => {
      const trimmedTeamName = modalState.teamName.trim();
      await teamApi.renameTeam(team.TeamId, {newTeamName: trimmedTeamName});
      renameTeamAction(team, trimmedTeamName);
      await onClose();
   };

   return (
      <BasicModalPortal
         visible={true}
         title={t('team.modal.rename.title')}
         width={cssConstants.defaultModalWidth}
         onClose={modalState.isWorking ? (): void => null : onClose}
         themeName={theme}
         isLoading={modalState.isWorking}
      >
         <div className="modal-body">
            <div className="form-body themeable-section">
               <div className="form-field-group">
                  {modalState.hasError && <StyledWarning>{StringUtil.isNullOrWhiteSpace(modalState.errorMessage) ? t('general.errorText') : modalState.errorMessage}</StyledWarning>}
                  <label>
                     {t('team.modal.rename.inputLabel')}
                     <TextInput
                        value={modalState.teamName}
                        onChange={updateTeamName}
                        themeName={theme}
                        ariaLabel={t('team.modal.rename.inputLabel')}
                        readOnly={false}
                        testId="rename-team-link-input"
                        disabled={modalState.isWorking}
                     />
                  </label>
               </div>
               <MinWidthButtonWrapper className="button-group">
                  <ControlButton
                     label={t('general.cancel')}
                     buttonType={TscButtonType.secondary}
                     disabled={modalState.isWorking}
                     themeName={theme}
                     onClick={modalState.isWorking ? (): void => null : onClose}
                     testId="rename-team-cancel-button"
                  />
                  <ControlButton
                     label={t('conversations.actions.rename')}
                     buttonType={TscButtonType.primary}
                     disabled={StringUtil.isNullOrWhiteSpace(modalState.teamName) || modalState.hasError}
                     themeName={theme}
                     onClick={renameTeam}
                     testId="rename-team-submit-button"
                  />
               </MinWidthButtonWrapper>
            </div>
         </div>
      </BasicModalPortal>
   );
};

export interface IRenameTeamModalProps {
   onClose(): void | Promise<void>;
   team: ITeamModel;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

const mapStatesToProps = (
   {onClose, team}: IRenameTeamModalProps,
   {theme}: Partial<IStateMappedProps>
): IStateMappedProps & IRenameTeamModalProps => ({onClose, team, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(RenameTeamModalBase);
