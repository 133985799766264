import React from 'react';
import {useTranslation} from 'react-i18next';
import DeleteModal from './DeleteModal';
import {ITeamModel} from '../../model/appModel';
import logging from '../../service/logging';
import teamApi from '../../service/teamApi';
import {removeTeam} from '../../context/teamsProvider';

export const DeleteTeamModalBase: React.FC<IDeleteTeamModalProps> = ({onClose, onDelete, team}) => {
   const {t} = useTranslation();

   const onDeleteAsync = async () => {
      try {
         await teamApi.deleteTeam(team.TeamId);
         removeTeam(team);
         onDelete();
         onClose();
      } catch (e) {
         logging.error(`Failed to delete team ${team.TeamId} with error: `, e);
      }
   };

   return (
      <DeleteModal
         title={t('team.modal.delete.title')}
         warning={t('team.modal.delete.warning', {teamName: team.Name})}
         onClose={onClose}
         onDelete={onDeleteAsync}
         deleteButtonText={t('team.modal.delete.confirmButtonText')}
      />
   );
};

export interface IDeleteTeamModalProps {
   onClose(): void;
   onDelete(): void;
   team: ITeamModel;
}

export default DeleteTeamModalBase;
