import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ControlButton, TscButtonType, TextInput, TscThemeNames} from '@techsmith/tsc-cloud-style-guide';
import Constants from '../../constants/Constants';
import cssConstants from '../../constants/cssConstants';
import {StyledWarning, MinWidthButtonWrapper} from '../util/StyledElements';
import '../../static/css/modal.less';
import {updateFoldersData} from '../../context/foldersProvider';
import config from '../../service/config';
import folderApi from '../../service/folderApi';
import {IWorkerState} from '../../model/workerState';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import {IMinimalFolder} from '../../model/minimalFolder';

const InputLabel = styled.label`
   display: block;
   margin-bottom: 0.5rem;
   font-size: .8rem;
   font-weight: 600;
`;

export const CreateOrRenameFolderModalBase: React.FC<ICreateOrRenameFolderModalProps & IStateMappedProps> = ({onClose, teamId, parentFolder, folderToRename, theme}) => {
   const {t} = useTranslation();
   const [state, setState] = React.useState<ICreateOrRenameFolderModalState>({
      folderName: folderToRename ? folderToRename.Name : '',
      errorText: '',
      isWorking: false,
      hasError: false
   });

   const onCloseClick = () => {
      onClose();
   };

   const updateFolderName = (evt: React.ChangeEvent<HTMLInputElement>): void => {
      const newFoldername = evt.target.value;
      const isEmpty = !newFoldername.trim();
      const isTooLong = newFoldername.length > Constants.maxFolderNameLength;
      const errorText = isEmpty ? t('folders.create.nameEmptyError') : isTooLong ? t('folders.create.nameTooLong', {maxLength: Constants.maxFolderNameLength}) : '';

      setState({...state, folderName: newFoldername, errorText: errorText});
   };

   const onCreateClick = async () => {
      const newFolderName = state.folderName.trim();

      setState({...state, isWorking: true, hasError: false});

      let updatedFolder: IMinimalFolder;
      try {
         if (folderToRename) {
            await folderApi.renameFolder(folderToRename.Hash, newFolderName);
            folderToRename.Name = newFolderName;
            updatedFolder = folderToRename;
         } else {
            updatedFolder = await folderApi.createFolder((config.libraryConfig?.profileTechSmithId || config.feedConfig?.profileTechSmithId) ?? config.user.TechSmithId, teamId ?? config.teamConfig?.teamId, newFolderName, parentFolder || '');
         }
         await updateFoldersData(config.libraryConfig?.profileTechSmithId || config.feedConfig?.profileTechSmithId, config.teamConfig?.teamId);
         onClose(updatedFolder);
      } catch {
         setState({...state, isWorking: false, hasError: true});
      }
   };

   const submitDisabled = Boolean(state.errorText || !state.folderName);

   const folderNameInput: React.JSX.Element = (
      <div className="form-field-group">
         <InputLabel>
            {t('folders.create.name')}
         </InputLabel>
         <TextInput
            placeholder={t('folders.create.placeholder', {0: Constants.maxFolderNameLength})}
            value={state.folderName}
            errorText={state.errorText}
            onChange={updateFolderName}
            themeName={theme}
            ariaLabel={t('folders.create.name')}
            readOnly={false}
            testId="folder-name-input"
            onEnter={submitDisabled ? null : onCreateClick}
         />
      </div>
   );

   const buttonGroup: React.JSX.Element = (
      <div className="button-group">
         <MinWidthButtonWrapper>
            <ControlButton
               label={t('general.cancel')}
               buttonType={TscButtonType.secondary}
               disabled={false}
               themeName={theme}
               onClick={onCloseClick}
               testId="cancel-button"
            />
            <ControlButton
               label={folderToRename ? t('folders.rename.label') : t('folders.create.label')}
               buttonType={TscButtonType.primary}
               disabled={submitDisabled}
               themeName={theme}
               onClick={onCreateClick}
               testId="create-button"
            />
         </MinWidthButtonWrapper>
      </div>
   );

   return (
      <BasicModalPortal
         visible={true}
         title={folderToRename ? t('folders.rename.instructions') : t('folders.create.instructions')}
         width={cssConstants.defaultModalWidth}
         onClose={onCloseClick}
         themeName={theme}
         isLoading={state.isWorking}
      >
         <div className="modal-body">
            <div className="form-body themeable-section">
               {state.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
               {folderNameInput}
               {buttonGroup}
            </div>
         </div>
      </BasicModalPortal>
   );
};

export interface ICreateOrRenameFolderModalProps {
   onClose(updatedFolder?: IMinimalFolder): void;
   parentFolder?: string;
   folderToRename?: IFolderTreeNode;
   teamId?: string;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
}

export interface ICreateOrRenameFolderModalState extends IWorkerState {
   folderName: string;
   errorText: string;
}

const mapStatesToProps = (
   {parentFolder, folderToRename, onClose, teamId}: ICreateOrRenameFolderModalProps,
   {theme}: Partial<IStateMappedProps>
): ICreateOrRenameFolderModalProps & IStateMappedProps => ({parentFolder, folderToRename, onClose, teamId, theme});

export default withMemoizedContexts(mapStatesToProps, themeStore)(CreateOrRenameFolderModalBase);
