import {IBaseAppModel, IEmbedAppModel, IEnvironmentDataModel, IFeatureSwitchesModel, ILauncherModel, ILibraryModel, IRegisterModel, IUserModel, IWatchAppModel, IStatusModel, IEntitlementStatus, IMigrationMediaDownloadModel, ISettingsAppModel, ITeamModel} from '../model/appModel';
import {IFolderTreeNode} from '../model/folderTreeNode';
import {IMediaDetails} from '../model/mediaDetails';
import {EntitlementNames, EntryPoint, StatusPages, UserPermissions, GroupRoleTypes} from '../constants/Constants';
import {IMigrationModel} from '../model/migrationModel';
import {IMigrationFileModel} from '../model/migrationFileModel';

const defaultSiteAccessEntitlement: IEntitlementStatus = {
   EntitlementName: EntitlementNames.knowmiaPro,
   IsActive: false,
   IsInTrial: false,
   DaysLeftInEntitlement: 0,
   HasPurchased: false,
   CanStartTrial: true,
   ContentExpirationDate: Date.now().toString()
};

let appEntryPoint: EntryPoint;
let environmentData: IEnvironmentDataModel;
let user: IUserModel;
let migrations: IMigrationModel[];
const folderMap: { [hash: string]: IFolderTreeNode } = {};
let featureSwitches: IFeatureSwitchesModel;
let userPermissions: string[];
let isAdmin: boolean;
let arePublicVideosEnabled: boolean;
let organizationName: string;
let applicationShortName: string;
let applicationFullName: string;
let sidebarLogoLinkUrl: string;
let sidebarShowCreate: boolean;
let sidebarEnableCreateFolder: boolean;
let sidebarEnableCreateCollection: boolean;
let sidebarEnableContextMenus: boolean;
let showPrivacySettingOnShareModal: boolean;
let sidebarShowMyCollections: boolean;
let sidebarShowMyFolders: boolean;
let viewersCanCopyMedia: boolean;
let isYouTubeEnabled: boolean;

let libraryConfig: ILibraryConfig;
let teamConfig: ITeamConfig;
let registerConfig: IRegisterConfig;
let watchConfig: IWatchConfig;
let embedConfig: IEmbedConfig;
let settingsConfig: ISettingsConfig;
let launcherConfig: ILauncherConfig;
let feedConfig: IFeedConfig;
let statusConfig: IStatusConfig;
let migrationFilesConfig: IMigrationFilesConfig;

const initBaseAppModel = (entryPoint: EntryPoint, config: IBaseAppModel) => {
   appEntryPoint = entryPoint;
   environmentData = config.EnvironmentData;
   user = config.User;
   migrations = config.UserMigrations;
   // TODO: this should come from the server model and route info (this will default it to false if it is unknown or unset)
   environmentData.IsLti = environmentData.IsLti === true;
   featureSwitches = config.FeatureSwitches;
   userPermissions = config.UserPermissions;
   isAdmin = config.UserPermissions.includes(UserPermissions.siteAdmin);
   arePublicVideosEnabled = config.ArePublicVideosEnabled;
   isYouTubeEnabled = config.IsYouTubeEnabled;
   organizationName = config.OrganizationName;
   applicationShortName = config.ApplicationShortName;
   applicationFullName = config.ApplicationFullName;
   sidebarLogoLinkUrl = config.SidebarLogoLinkUrl;
   sidebarShowCreate = false;
   sidebarEnableCreateFolder = false;
   sidebarEnableCreateCollection = false;
   sidebarEnableContextMenus = false;
   showPrivacySettingOnShareModal = false;
   sidebarShowMyCollections = true;
   sidebarShowMyFolders = true;
   // this var is not defined on the base model, but is defined on watch and library
   viewersCanCopyMedia = false;

   // null these out so they aren't leftover, normal runtime should not call repeat and conflicting init funcs, but this helps with tests
   libraryConfig = null;
   teamConfig = null;
   registerConfig = null;
   watchConfig = null;
   embedConfig = null;
   settingsConfig = null;
   launcherConfig = null;
   feedConfig = null;
   statusConfig = null;

   // this is a convenience accessor for this 'well-known' entitlement that is used throughout the code,
   // the cast at the beginning allows us to set a readonly property on an interface but prevent it from being sets elsewhere
   (user as {SiteAccessEntitlement: IEntitlementStatus}).SiteAccessEntitlement = user.Entitlements?.find(e => e.EntitlementName.toLowerCase() === EntitlementNames.knowmiaPro.toLowerCase()) ?? defaultSiteAccessEntitlement;
};

const initWatch = (config: IWatchAppModel) => {
   initBaseAppModel(EntryPoint.watch, config);

   watchConfig = {
      folder: config.Folder,
      media: config.Media,
      isOwnerOrAdmin: config.LoggedInUserIsContentOwner || isAdmin,
      isTeamEditor: config.LoggedInUserIsMediaTeamEditor,
      isOwner: config.LoggedInUserIsContentOwner,
      isOwnerAdminOrTeamEditor: config.LoggedInUserIsContentOwner || config.LoggedInUserIsMediaTeamEditor || isAdmin,
      isCaptionAdminOrAssignee: config.UserPermissions.includes(UserPermissions.captionAdmin) || config.User.EmailAddress === config.Media.CaptioningAssignedToEmail,
      viewersCanShareMedia: config.ViewersCanShareMedia,
      viewersCanDownloadMedia: config.ViewersCanDownloadMedia,
      referringGroup: config.ReferringGroup,
      maxTitleLength: config.WatchPageParameters.MaxTitleLength,
      maxConversationTitleLength: config.WatchPageParameters.MaxConversationTitleLength,
      maxDescriptionLength: config.WatchPageParameters.MaxDescriptionLength,
      disableProductBanner: config.DisableProductBanner
   };

   !!watchConfig.folder.SubFolders?.length && watchConfig.folder.SubFolders?.forEach(subfolder => {
      folderMap[subfolder.Hash] = subfolder;
      subfolder.SubFolders?.forEach(subSubfolder => {
         folderMap[subSubfolder.Hash] = subSubfolder;
      });
   });

   // TODO: this should come from the server model and route info (this will default it to false if it is unknown or unset)
   environmentData.IsLti = environmentData.IsLti === true;
   viewersCanCopyMedia = config.ViewersCanCopyMedia;
   showPrivacySettingOnShareModal = watchConfig.isOwnerAdminOrTeamEditor;
};

const initEmbed = (config: IEmbedAppModel) => {
   initBaseAppModel(EntryPoint.embed, config);

   embedConfig = {
      media: config.Media,
      isOwner: config.LoggedInUserIsContentOwner,
      disableProductBanner: config.DisableProductBanner
   };
};

const initSettings = (config: ISettingsAppModel) => {
   initBaseAppModel(EntryPoint.settings, config);

   settingsConfig = {
      customSiteLogoUrl: config.UserSettings.CustomSiteLogoUrl,
      enableProductBanner: config.UserSettings.EnableProductBanner,
      enableUserAutoCaptions: config.UserSettings.EnableUserAutoCaptions,
      enableNewUploadConversations: config.UserSettings.EnableNewUploadConversations
   };
};

const initWhatsNew = (config: IBaseAppModel) => {
   initBaseAppModel(EntryPoint.whatsNew, config);
};

const initNoAccess = (config: IBaseAppModel) => {
   initBaseAppModel(EntryPoint.noAccess, config);
};

const initLibrary = (config: ILibraryModel) => {
   initBaseAppModel(EntryPoint.library, config);
   libraryConfig = {
      profileTechSmithId: config.ProfileTechSmithId,
      profileEmailAddress: config.ProfileEmailAddress,
      profileDisplayName: config.ProfileDisplayName,
      loggedInUserIsProfileOwner: config.LoggedInUserIsProfileOwner,
      loggedInUserHasCreatePermission: config.LoggedInUserHasCreatePermission,
      canCreateContent: config.CanCreateContent,
      canAdministerProfile: config.CanAdministerProfile
   };
   // override these config for library view to take into account what profile is being viewed and permissions
   sidebarShowCreate = config.UserPermissions.includes(UserPermissions.canCreate) && config.LoggedInUserIsProfileOwner;
   sidebarEnableCreateFolder = config.UserPermissions.includes(UserPermissions.canCreate) && (config.LoggedInUserIsProfileOwner || config.CanAdministerProfile);
   sidebarEnableCreateCollection = config.UserPermissions.includes(UserPermissions.canCreate) && config.LoggedInUserIsProfileOwner;
   showPrivacySettingOnShareModal = libraryConfig.canAdministerProfile;
   sidebarShowMyCollections = config.LoggedInUserIsProfileOwner;
   sidebarShowMyFolders = config.LoggedInUserIsProfileOwner;
   sidebarEnableContextMenus = true;
   viewersCanCopyMedia = config.ViewersCanCopyMedia;
};

const initTeams = (config: ITeamModel) => {
   initBaseAppModel(EntryPoint.teams, config);

   teamConfig = {
      teamId: config.TeamId,
      name: config.Name,
      loggedInUserIsTeamOwner: config.LoggedInUserIsTeamOwner ?? config.Role === GroupRoleTypes.manager,
      loggedInUserHasCreatePermission: config.LoggedInUserHasCreatePermission ?? (config.Role === GroupRoleTypes.manager || config.Role === GroupRoleTypes.contributor)
   };

   sidebarShowCreate = config.UserPermissions.includes(UserPermissions.canCreate) && config.LoggedInUserHasCreatePermission;
   sidebarEnableCreateFolder = config.UserPermissions.includes(UserPermissions.canCreate) && (config.LoggedInUserHasCreatePermission);
   showPrivacySettingOnShareModal = config.LoggedInUserHasCreatePermission;
   sidebarShowMyCollections = config.LoggedInUserHasCreatePermission;
   sidebarShowMyFolders = true;
   sidebarEnableContextMenus = true;
   viewersCanCopyMedia = config.LoggedInUserHasCreatePermission;
};

const initFeed = (config: ILibraryModel) => {
   initBaseAppModel(EntryPoint.feed, config);

   feedConfig = {
      profileTechSmithId: config.ProfileTechSmithId,
      profileEmailAddress: config.ProfileEmailAddress,
      profileDisplayName: config.ProfileDisplayName,
      loggedInUserIsProfileOwner: config.LoggedInUserIsProfileOwner,
      loggedInUserHasCreatePermission: config.LoggedInUserHasCreatePermission,
      canCreateContent: config.CanCreateContent,
      canAdministerProfile: config.CanAdministerProfile,
      showSidebar: config.User.IsSignedIn &&
         // Always show for signed in user on enterprise
         !config.FeatureSwitches.UseEntitlementAuthorization ||
         // Only show for knowmia pro trial users or users that have purchased
         (config.FeatureSwitches.UseEntitlementAuthorization && (config.User.SiteAccessEntitlement.IsInTrial || config.User.SiteAccessEntitlement.HasPurchased))
   };
   sidebarShowCreate = config.UserPermissions.includes(UserPermissions.canCreate) && config.LoggedInUserIsProfileOwner;
   sidebarEnableCreateFolder = config.UserPermissions.includes(UserPermissions.canCreate) && (config.LoggedInUserIsProfileOwner || config.CanAdministerProfile);
   sidebarShowMyCollections = config.LoggedInUserIsProfileOwner;
   sidebarShowMyFolders = config.LoggedInUserIsProfileOwner;
   sidebarEnableCreateCollection = config.UserPermissions.includes(UserPermissions.canCreate) && config.LoggedInUserIsProfileOwner;
   sidebarEnableContextMenus = true;
};

const initRegister = (config: IRegisterModel) => {
   initBaseAppModel(EntryPoint.register, config);
   registerConfig = {
      registrationStep: config.RegistrationStep,
      stepSuccessRedirect: config.StepSuccessRedirect,
      userHasAnyMedia: config.UserHasAnyMedia,
      knowmiaProPurchaseTrialPageUrl: config.KnowmiaProPurchaseTrialPageUrl,
      knowmiaProPurchaseExpiredEntitlementUrl: config.KnowmiaProPurchaseExpiredEntitlementUrl,
      trialDeclineUrl: config.TrialDeclineUrl,
      trialExpiredLearnMoreUrl: config.TrialDeclineUrl
   };
};

const initLauncher = (config: ILauncherModel) => {
   initBaseAppModel(EntryPoint.launcher, config);
   launcherConfig = {
      windowsRecorder64BitCdnUrl: config.WindowsRecorder64BitCdnUrl,
      windowsRecorder32BitCdnUrl: config.WindowsRecorder32BitCdnUrl,
      macRecorderCdnUrl: config.MacRecorderCdnUrl
   };
};

const initStatus = (config: IStatusModel) => {
   initBaseAppModel(EntryPoint.header, config);
   statusConfig = {
      allowedEmailDomains: config.AllowedEmailDomains,
      returnUrl: config.ReturnUrl,
      signInNewTabUrl: config.SignInNewTabUrl,
      signOutRedirectToSignInUrl: config.SignOutRedirectToSignInUrl,
      statusPage: config.StatusPage
   };
};

const initMigrationMediaDownload = (config: IMigrationMediaDownloadModel) => {
   initBaseAppModel(EntryPoint.migrationMediaDownload, config);
   migrationFilesConfig = {
      exportStatus: config.ExportStatus,
      archiveFiles: config.ArchiveFiles
   };
};

export interface IWatchConfig {
   readonly folder: IFolderTreeNode;
   readonly media: IMediaDetails;
   readonly isOwnerOrAdmin: boolean;
   readonly isOwner: boolean;
   readonly isTeamEditor: boolean;
   readonly isOwnerAdminOrTeamEditor: boolean;
   readonly isCaptionAdminOrAssignee: boolean;
   readonly viewersCanShareMedia: boolean;
   readonly viewersCanDownloadMedia: boolean;
   readonly referringGroup: string;
   readonly maxTitleLength: number;
   readonly maxConversationTitleLength: number;
   readonly maxDescriptionLength: number;
   readonly disableProductBanner: boolean;
}

export interface IEmbedConfig {
   readonly media: IMediaDetails;
   readonly isOwner: boolean;
   readonly disableProductBanner: boolean;
}

export interface ISettingsConfig {
   readonly customSiteLogoUrl: string;
   readonly enableProductBanner: boolean;
   readonly enableUserAutoCaptions: boolean;
   readonly enableNewUploadConversations: boolean;
}

export interface ILibraryConfig {
   readonly profileTechSmithId: string;
   readonly profileEmailAddress: string;
   readonly profileDisplayName: string;
   readonly loggedInUserIsProfileOwner: boolean;
   readonly loggedInUserHasCreatePermission: boolean;
   readonly canCreateContent: boolean;
   readonly canAdministerProfile: boolean;
}

export interface ITeamConfig {
   readonly teamId: string;
   readonly name: string;
   readonly loggedInUserIsTeamOwner: boolean;
   readonly loggedInUserHasCreatePermission: boolean;
}

export interface IFeedConfig {
   readonly profileTechSmithId: string;
   readonly profileEmailAddress: string;
   readonly profileDisplayName: string;
   readonly loggedInUserIsProfileOwner: boolean;
   readonly loggedInUserHasCreatePermission: boolean;
   readonly canCreateContent: boolean;
   readonly canAdministerProfile: boolean;
   showSidebar: boolean;
}

export interface IRegisterConfig {
   readonly registrationStep: string;
   readonly stepSuccessRedirect: string;
   readonly userHasAnyMedia: boolean;
   readonly trialDeclineUrl: string;
   readonly knowmiaProPurchaseTrialPageUrl: string;
   readonly trialExpiredLearnMoreUrl: string;
   readonly knowmiaProPurchaseExpiredEntitlementUrl: string;
}

export interface ILauncherConfig {
   readonly windowsRecorder64BitCdnUrl: string;
   readonly windowsRecorder32BitCdnUrl: string;
   readonly macRecorderCdnUrl: string;
}

export interface IStatusConfig {
   readonly allowedEmailDomains: string;
   readonly returnUrl: string;
   readonly signInNewTabUrl: string;
   readonly signOutRedirectToSignInUrl: string;
   readonly statusPage: StatusPages;
}

export interface IMigrationFilesConfig {
   readonly exportStatus: string;
   readonly archiveFiles: IMigrationFileModel[];
}

export default {
   initWatch,
   initBaseAppModel,
   initLibrary,
   initTeams,
   initRegister,
   initEmbed,
   initLauncher,
   initFeed,
   initStatus,
   initMigrationMediaDownload,
   initSettings,
   initWhatsNew,
   initNoAccess,
   get entryPoint() {
      return appEntryPoint;
   },
   get environmentData() {
      return environmentData;
   },
   get user() {
      return user;
   },
   get migrations() {
      return migrations;
   },
   get folderMap() {
      return folderMap;
   },
   get featureSwitches() {
      return featureSwitches;
   },
   get userPermissions() {
      return userPermissions;
   },
   get isAdmin() {
      return isAdmin;
   },
   get arePublicVideosEnabled() {
      return arePublicVideosEnabled;
   },
   get IsYouTubeEnabled() {
      return isYouTubeEnabled;
   },
   get viewersCanCopyMedia() {
      return viewersCanCopyMedia;
   },
   get organizationName() {
      return organizationName;
   },
   get applicationShortName() {
      return applicationShortName;
   },
   get applicationFullName() {
      return applicationFullName;
   },
   get sidebarLogoLinkUrl() {
      return sidebarLogoLinkUrl;
   },
   get sidebarShowCreate() {
      return sidebarShowCreate;
   },
   get sidebarShowMyCollections() {
      return sidebarShowMyCollections;
   },
   get sidebarShowMyFolders() {
      return sidebarShowMyFolders;
   },
   get sidebarEnableCreateFolder() {
      return sidebarEnableCreateFolder;
   },
   get sidebarEnableCreateCollection() {
      return sidebarEnableCreateCollection;
   },
   get showPrivacySettingOnShareModal() {
      return showPrivacySettingOnShareModal;
   },
   get sidebarEnableContextMenus() {
      return sidebarEnableContextMenus;
   },
   get libraryConfig() {
      return libraryConfig;
   },
   get teamConfig() {
      return teamConfig;
   },
   get registerConfig() {
      return registerConfig;
   },
   get watchConfig() {
      return watchConfig;
   },
   get embedConfig() {
      return embedConfig;
   },
   get settingsConfig() {
      return settingsConfig;
   },
   get launcherConfig() {
      return launcherConfig;
   },
   get feedConfig() {
      return feedConfig;
   },
   get statusConfig() {
      return statusConfig;
   },
   get migrationFilesConfig() {
      return migrationFilesConfig;
   }
};
