import React from 'react';
import {useTranslation} from 'react-i18next';
import {
   ControlButton,
   TscButtonType,
   Glyphs,
   SelectInput,
   TscThemeNames
} from '@techsmith/tsc-cloud-style-guide';
import cssConstants from '../../constants/cssConstants';
import {MinWidthButtonWrapper, StyledWarning} from '../util/StyledElements';
import '../../static/css/modal.less';
import {IFolderTreeNode} from '../../model/folderTreeNode';
import {ITeamModel, ITeamModelFolderTree} from '../../model/appModel';
import {IWorkerState} from '../../model/workerState';
import folderApi from '../../service/folderApi';
import config from '../../service/config';
import styled from 'styled-components';
import {BasicModalPortal} from '../util/ModalPortalHelper';
import withMemoizedContexts from '../../context/contextContainerHoC';
import {themeStore} from '../../context/themeProvider';
import {IMediaDetails} from '../../model/mediaDetails';
import {foldersStore} from '../../context/foldersProvider';
import {teamsStore, updateUserTeamsWithFolderTrees} from '../../context/teamsProvider';
import Constants, {GroupRoleTypes} from '../../constants/Constants';
import teamApi, {IMoveMediaRequestBody} from '../../service/teamApi';

const ErrorContainer = styled.div`
   margin-bottom: 1rem;
`;

const FooterButtonsWrapper = styled.div<{useMinWidthButtons: boolean}>`
   display: flex;
   justify-content: space-between;
   .button-group {
      margin-left: auto;
   }

   button {
      min-width: ${props => props.useMinWidthButtons ? cssConstants.defaultButtonWidth : ''};
   }
`;

export const MoveModalBase: React.FC<IMoveModalProps & IStateMappedProps> = ({onClose, onMove, onCreateFolder, selectedMedia, selectedFolders, destinationFolderHash, theme, personalFolderTree, activeTeam, userTeamsFolderTree}) => {
   const {t} = useTranslation();
   const [modalState, setModalState] = React.useState<IMoveModalState>({
      destination: destinationFolderHash,
      isWorking: false,
      hasError: false
   });
   React.useEffect(() => {
      !!userTeamsFolderTree?.length || updateUserTeamsWithFolderTrees();
   }, []);

   React.useEffect(() => {
      setModalState({...modalState, destination: destinationFolderHash});
   }, [destinationFolderHash]);

   // because media-filters we can no longer assume all batch content will come from the same directory, so we have to
   // consider all media and just fallback to 'move wherever' if they are not all from the same top-level directory

   const selectedContentParents = (selectedMedia.concat(selectedFolders as any[]) as (IMediaDetails | IFolderTreeNode)[]).map(s => s.ParentFolder);
   // eslint-disable-next-line no-undefined
   const parentFolder = selectedContentParents.length !== 0 && selectedContentParents.every((m, _, all) => m?.Hash === all[0]?.Hash) ? selectedContentParents[0] ?? null : undefined;
   const isSubfolderCreationAllowed = !parentFolder || !parentFolder.Hash; // this is indicator that identified parent folder is not 2-levels deep
   const isViewingTeamLibrary = !!activeTeam;
   const disableRoot = (parentFolder === null && !isViewingTeamLibrary) || (selectedFolders.length !== 0 && isViewingTeamLibrary);

   const options = [
      {label: t('folders.move.selectPrompt'), value: ''},
      {label: t('details.rootFolderName'), value: `${Constants.myLibrary}.root`, disabled: disableRoot} // null indicates root folder, undefined indicates mix-match selection
   ];
   const divider = {label: '─────────', value: '', disabled: true};

   personalFolderTree?.SubFolders.forEach(f => {
      options.push({
         label: `- ${f.Name}`,
         value: `${Constants.myLibrary}.${f.Hash}`,
         disabled: selectedFolders.some(fo => f.Hash === fo.Hash) || (f.Hash === parentFolder?.Hash) || (activeTeam && selectedFolders.length > 0)
      });

      f.SubFolders.forEach(subfolder => options.push({
         label: `\u00A0\u00A0\u00A0- ${subfolder.Name}`,
         value: `${Constants.myLibrary}.${subfolder.Hash}`,
         disabled: selectedFolders.length > 0 || (subfolder.Hash === parentFolder?.Hash) || (activeTeam && selectedFolders.length > 0)
      }));
   });
  
   userTeamsFolderTree?.forEach(team => {
      if (team.Role === GroupRoleTypes.manager || team.Role === GroupRoleTypes.contributor) {
         // Can't move folders to team libraries. Folder API currently does not support movement between libraries.
         if (team.TeamId === activeTeam?.TeamId || selectedFolders.length === 0) {
            options.push(divider);
            options.push({
               label: team.Name,
               value: `${team.TeamId}.root`,
               disabled: team.TeamId === activeTeam?.TeamId && parentFolder === null // Disable if currently viewing team library
            });

            team.Foldertree?.SubFolders.forEach(f => {
               options.push({
                  label: `- ${f.Name}`,
                  value: `${team.TeamId}.${f.Hash}`,
                  disabled: selectedFolders.some(fo => f.Hash === fo.Hash) || f.Hash === parentFolder?.Hash
               });
      
               f.SubFolders.forEach(subfolder => options.push({
                  label: `\u00A0\u00A0\u00A0- ${subfolder.Name}`,
                  value: `${team.TeamId}.${subfolder.Hash}`,
                  disabled: selectedFolders.length > 0 || subfolder.Hash === parentFolder?.Hash
               }));
            });
         }
      }
   });
   
   const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setModalState({...modalState, destination: e.target.value});
   };

   const onSubmit = async () => {
      setModalState({...modalState, isWorking: true, hasError: false});
      try {
         const mediaHashesToMove = selectedMedia.map(m => m.Hash);
         const folderHashesToMove = selectedFolders.map(f => f.Hash);
         const destinationLibrary = modalState.destination.split('.')[0];
         const destinationFolder = modalState.destination.split('.')[1];

         if (destinationLibrary !== activeTeam?.TeamId && destinationLibrary !== Constants.myLibrary) {
            // if moving to team library
            const moveMediaRequest: IMoveMediaRequestBody = {
               MediaHashes: mediaHashesToMove,
               ToTeamLibrary: true
            };
            await teamApi.moveMedia(destinationLibrary, moveMediaRequest);
            if (destinationFolder !== 'root') {
               await folderApi.updateParentFolder(mediaHashesToMove, folderHashesToMove, destinationFolder, config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId, destinationLibrary);
            }
         } else if (isViewingTeamLibrary && destinationLibrary === Constants.myLibrary) {
            // if moving to personal library
            const moveMediaRequest: IMoveMediaRequestBody = {
               MediaHashes: mediaHashesToMove,
               ToTeamLibrary: false
            };
            await teamApi.moveMedia(activeTeam.TeamId, moveMediaRequest);
            if (destinationFolder !== 'root') {
               await folderApi.updateParentFolder(mediaHashesToMove, folderHashesToMove, destinationFolder, config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId);
            }
         } else if ((!isViewingTeamLibrary && destinationLibrary === Constants.myLibrary) || (isViewingTeamLibrary && destinationLibrary === activeTeam.TeamId)) {
            // if moving within a library (between folders)
            await folderApi.updateParentFolder(mediaHashesToMove, folderHashesToMove, destinationFolder, config.libraryConfig?.profileTechSmithId ?? config.user.TechSmithId, activeTeam?.TeamId);
         }
         
         onMove();

         onClose();
      } catch (e) {
         setModalState({...modalState, isWorking: false, hasError: true});
      }
   };

   const showSubfolderError = selectedFolders.some(f => f.SubFolders.length > 0);

   const onCreateFolderClick = () => {
      onCreateFolder && onCreateFolder();
   };

   const modalTitle = selectedMedia?.length > 0 && selectedFolders?.length > 0 ? t('folders.move.modalContentTitle') :
      selectedFolders?.length > 0 ? t('folders.move.modalFolderTitle') : t('folders.move.modalMediaTitle');

   return (
      <BasicModalPortal
         testId="move-modal"
         isLoading={modalState.isWorking}
         visible={true}
         title={showSubfolderError ? t('folders.move.cannotMoveTitle') : modalTitle}
         width={cssConstants.defaultModalWidth}
         onClose={() => onClose()}
         themeName={theme}
      >
         <div className="modal-body">
            <div className="form-body themeable-section">
               {modalState.hasError && <StyledWarning>{t('general.errorText')}</StyledWarning>}
               {showSubfolderError ? (
                  <>
                     <ErrorContainer>{t('folders.move.cannotMoveDescription')}</ErrorContainer>
                     <div className="button-group">
                        <MinWidthButtonWrapper>
                           <ControlButton
                              label={t('general.ok')}
                              buttonType={TscButtonType.secondary}
                              themeName={theme}
                              onClick={() => onClose()}
                           />
                        </MinWidthButtonWrapper>
                     </div>
                  </>
               ) : (
                  <>
                     <div className="form-field-group">
                        <SelectInput label=" " className={'t-destination'} onChange={onSelectChange} value={modalState.destination} options={options} themeName={theme} isFullWidth={true} />
                     </div>
                     <FooterButtonsWrapper useMinWidthButtons={!onCreateFolder}>
                        {isSubfolderCreationAllowed && onCreateFolder && <ControlButton
                           label={t('folders.create.new')}
                           buttonType={TscButtonType.tertiary}
                           glyph={<Glyphs.Add16x16 />}
                           themeName={theme}
                           onClick={onCreateFolderClick}
                           testId="create-folder-button"
                        />}
                        <div className="button-group">
                           <ControlButton
                              label={t('general.close')}
                              buttonType={TscButtonType.secondary}
                              themeName={theme}
                              onClick={() => onClose()}
                           />
                           <ControlButton
                              label={t('folders.move.moveButtonText')}
                              buttonType={TscButtonType.primary}
                              themeName={theme}
                              onClick={onSubmit}
                              disabled={!modalState.destination}
                              testId={'move-button'}
                           />
                        </div>
                     </FooterButtonsWrapper>
                  </>
               )}
            </div>
         </div>
      </BasicModalPortal>
   );
};

MoveModalBase.defaultProps = {
   selectedMedia: [],
   selectedFolders: [],
   destinationFolderHash: ''
};

export interface IMoveModalProps {
   onClose(): void;
   onMove(): void;
   onCreateFolder?: () => void;
   selectedMedia?: IMediaDetails[];
   selectedFolders?: IFolderTreeNode[];
   destinationFolderHash?: string;
}

export interface IMoveModalState extends IWorkerState {
   destination: string;
}

export interface IStateMappedProps {
   theme: TscThemeNames;
   personalFolderTree: IFolderTreeNode;
   activeTeam: ITeamModel;
   userTeamsFolderTree: ITeamModelFolderTree[];
}

const mapStatesToProps = (
   {onClose, onMove, onCreateFolder, selectedMedia, selectedFolders, destinationFolderHash}: IMoveModalProps,
   {theme}: Partial<IStateMappedProps>,
   {personalFolderTree}: Partial<IStateMappedProps>,
   {activeTeam, userTeamsFolderTree}: Partial<IStateMappedProps>
): IStateMappedProps & IMoveModalProps => ({onClose, onMove, onCreateFolder, selectedMedia, selectedFolders, destinationFolderHash, theme, personalFolderTree, activeTeam, userTeamsFolderTree});

export default withMemoizedContexts(mapStatesToProps, themeStore, foldersStore, teamsStore)(MoveModalBase);
