import React, {createContext, ReactElement, useState} from 'react';
import {IFolderTreeNode} from '../model/folderTreeNode';
import folderApi from '../service/folderApi';
import config from '../service/config';
import {History} from 'history';
import {useHistory, useRouteMatch} from 'react-router-dom';
import Constants, {EntryPoint} from '../constants/Constants';
import logging from '../service/logging';
import {getWellKnownFolderDisplayName, getWellKnownFolderPermalink, isWellKnownFolder, IWellKnownFolder} from '../constants/wellKnownFolder';
import {updateUserTeamsWithFolderTrees} from './teamsProvider';

interface IFoldersProvider {
   activeFolder: IFolderTreeNode;
   allPersonalFolders: IFolderTreeNode[];
   personalFolderTree: IFolderTreeNode;
   allTeamFolders: IFolderTreeNode[];
   teamFolderTree: IFolderTreeNode;
   history: History;
   routeMatchFolderHash: string;
}

const initialState: IFoldersProvider = {
   activeFolder: null,
   allPersonalFolders: [] as IFolderTreeNode[],
   personalFolderTree: null,
   allTeamFolders: [] as IFolderTreeNode[],
   teamFolderTree: null,
   history: null,
   routeMatchFolderHash: null
};

const store = createContext<IFoldersProvider>(initialState);
const Provider = store.Provider;
let privateState = initialState;
let privateUpdateState: React.Dispatch<React.SetStateAction<IFoldersProvider>> = () => null;
const publicUpdateState = (newState: Partial<IFoldersProvider>) => privateUpdateState(Object.assign({}, privateState, newState));

const updateFoldersDataFromServer = async (techSmithId?: string, teamId?: string, rootFolderTreeNodeCacheValueString?: string) => {
   try {
      const rootFolderTreeNode = await folderApi.getFolderTree(techSmithId, teamId);

      if (techSmithId && !teamId) {
         const rootFolderTreeNodeAsString = JSON.stringify(rootFolderTreeNode);

         if (rootFolderTreeNodeAsString !== rootFolderTreeNodeCacheValueString) {
            localStorage.setItem(`user/${techSmithId}/folders`, rootFolderTreeNodeAsString);
         }
      }

      let topFolderTreeNode = rootFolderTreeNode;
      if (!config.libraryConfig?.canAdministerProfile && !config.teamConfig?.loggedInUserHasCreatePermission && !config.feedConfig?.canAdministerProfile && privateState.routeMatchFolderHash) {
         // Viewers of a folder cannot traverse the entire folder tree. In this case they only can navigate to the folder and it's subfolders (aka. folder branch).
         topFolderTreeNode = await folderApi.getFolderBranch(privateState.routeMatchFolderHash);
      }
      const allFolders = Object.assign([], [topFolderTreeNode].concat(topFolderTreeNode.SubFolders.concat(topFolderTreeNode.SubFolders.flatMap((f: IFolderTreeNode) => f.SubFolders))));

      let activeFolder = topFolderTreeNode;
      if (privateState.routeMatchFolderHash) {
         const matchingFolder = allFolders.find(f => f.Hash === privateState.routeMatchFolderHash);
         activeFolder = matchingFolder || activeFolder;
      }

      if (teamId) {
         publicUpdateState({teamFolderTree: topFolderTreeNode, allTeamFolders: allFolders, activeFolder: activeFolder});
      } else {
         publicUpdateState({personalFolderTree: topFolderTreeNode, allPersonalFolders: allFolders, activeFolder: activeFolder});
      }
   } catch (e) {
      logging.error('Failed to update folders data from server: ', e);
   }
};

const updateFoldersDataFromCache = (techSmithId: string): string => {
   try {
      const rootFolderTreeNodeCacheValueString = localStorage.getItem(`user/${techSmithId}/folders`);
      const rootFolderTreeNodeCacheValue = JSON.parse(rootFolderTreeNodeCacheValueString) as IFolderTreeNode;
      const allPersonalFolders = Object.assign([], rootFolderTreeNodeCacheValue.SubFolders.concat(rootFolderTreeNodeCacheValue.SubFolders.flatMap((f: IFolderTreeNode) => f.SubFolders)));
      if (rootFolderTreeNodeCacheValue) {
         publicUpdateState({personalFolderTree: rootFolderTreeNodeCacheValue, allPersonalFolders: allPersonalFolders});
      }

      return rootFolderTreeNodeCacheValueString;
   } catch (e) {
      logging.error('Failed to update folders from cache: ', e);
      return null;
   }
};

const createSpecialFolder = (specialFolder: IWellKnownFolder): IFolderTreeNode => ({
   Hash: specialFolder,
   Owner: config.user,
   Name: getWellKnownFolderDisplayName(specialFolder),
   ParentFolder: null,
   SubFolders: []
});

let isUpdating = false;
export const updateFoldersData = async (techSmithId: string, teamId: string | null = null) => {
   if (!isUpdating) {
      isUpdating = true;
      const rootFolderTreeNodeCacheValueString = updateFoldersDataFromCache(techSmithId);
      await updateFoldersDataFromServer(techSmithId, teamId, rootFolderTreeNodeCacheValueString);
      await updateUserTeamsWithFolderTrees();
      isUpdating = false;
   }
};

export const updateActiveFolderToSpecial = (specialFolder: IWellKnownFolder) => {
   publicUpdateState({
      routeMatchFolderHash: specialFolder,
      activeFolder: createSpecialFolder(specialFolder)
   });
   privateState.history.push(getWellKnownFolderPermalink(specialFolder));
};

export const clearActiveFolder = () => {
   publicUpdateState({routeMatchFolderHash: null, activeFolder: null});
};

export const updateActiveFolder = (folder: IFolderTreeNode, teamId?: string | null) => {
   publicUpdateState({routeMatchFolderHash: folder.Hash, activeFolder: folder});
   updateHistory(folder.Hash, teamId);
};

const updateHistory = (folderHash: string, teamId?: string) => {
   if (folderHash) {
      if (teamId) {
         privateState.history.push(`${Constants.navigation.teamsLink}/${teamId}/folder/${folderHash}`);
      } else {
         privateState.history.push(`/folder/${folderHash}`);
      }
   } else {
      let libraryLocation;
      if (config.entryPoint === EntryPoint.teams) {
         libraryLocation = `${Constants.navigation.teamsLink}/${teamId}`;
      } else {
         libraryLocation = Constants.navigation.myLibraryLink;
      }
      privateState.history.push(libraryLocation);
   }
};

const updateActiveFolderFromRouteMatch = (folder: IFolderTreeNode) => {
   if (folder && folder.Hash !== privateState.activeFolder?.Hash) {
      publicUpdateState({routeMatchFolderHash: folder.Hash ?? '', activeFolder: folder});
   }
};

const updateSpecialFolderFromRouteMatch = (specialFolder: IWellKnownFolder) => {
   if (isWellKnownFolder(specialFolder) && specialFolder !== privateState.activeFolder?.Hash) {
      publicUpdateState({
         routeMatchFolderHash: specialFolder,
         activeFolder: createSpecialFolder(specialFolder)
      });
   }
};

interface folderRouteParams {
   hash: string;
}

interface userLibraryRouteParams {
   techSmithId: string;
}

interface teamFolderRouteParams {
   teamHash: string;
   hash: string;
}

const useProvider = (children: ReactElement) => {
   initialState.history = useHistory();
   const [state, updateState] = useState<IFoldersProvider>(initialState);
   privateUpdateState = updateState;
   privateState = state;

   const folderRouteMatch = useRouteMatch<folderRouteParams>('/folder/:hash');
   if (folderRouteMatch && folderRouteMatch.params && folderRouteMatch.params.hash) {
      privateState.routeMatchFolderHash = folderRouteMatch.params.hash;
      const matchingFolder = privateState.allPersonalFolders.find(f => f.Hash === folderRouteMatch.params.hash);
      matchingFolder && updateActiveFolderFromRouteMatch(matchingFolder);
   }

   const teamFolderRouteMatch = useRouteMatch<teamFolderRouteParams>('/teams/:teamHash/folder/:hash');
   if (teamFolderRouteMatch && teamFolderRouteMatch.params && teamFolderRouteMatch.params.hash) {
      privateState.routeMatchFolderHash = teamFolderRouteMatch.params.hash;
      const matchingFolder = privateState.allTeamFolders.find(f => f.Hash === teamFolderRouteMatch.params.hash);
      matchingFolder && updateActiveFolderFromRouteMatch(matchingFolder);
   }

   const localLibraryRouteMatch = useRouteMatch('/library.html');
   if (config.environmentData.IsRunningLocally && localLibraryRouteMatch && localLibraryRouteMatch.isExact) {
      updateActiveFolderFromRouteMatch(privateState.personalFolderTree);
   }

   const recentMediaRouteMatchSelf = useRouteMatch(Constants.navigation.myLibraryRecentMediaLink);
   const allVideosRouteMatchSelf = useRouteMatch(Constants.navigation.myLibraryAllVideosLink);
   const allImagesRouteMatchSelf = useRouteMatch(Constants.navigation.myLibraryAllImagesLink);
   const libraryRouteMatch = useRouteMatch(Constants.navigation.myLibraryLink);
   const teamsRouteMatch = useRouteMatch('/teams/:hash');
  
   if (recentMediaRouteMatchSelf && recentMediaRouteMatchSelf.isExact) {
      updateSpecialFolderFromRouteMatch(IWellKnownFolder.recentMedia);
   } else if (allVideosRouteMatchSelf && allVideosRouteMatchSelf.isExact) {
      updateSpecialFolderFromRouteMatch(IWellKnownFolder.allVideo);
   } else if (allImagesRouteMatchSelf && allImagesRouteMatchSelf.isExact) {
      updateSpecialFolderFromRouteMatch(IWellKnownFolder.allImage);
   } else if (libraryRouteMatch && libraryRouteMatch.isExact) {
      updateActiveFolderFromRouteMatch(privateState.personalFolderTree);
   } else if (teamsRouteMatch && teamsRouteMatch.isExact) {
      updateActiveFolderFromRouteMatch(privateState.teamFolderTree);
   }

   const userLibraryRouteMatch = useRouteMatch<userLibraryRouteParams>('/users/:techSmithId');
   if (userLibraryRouteMatch && userLibraryRouteMatch.isExact && userLibraryRouteMatch.params.techSmithId === config.libraryConfig?.profileTechSmithId) {
      if (window.location.hash) {
         privateState.routeMatchFolderHash = window.location.hash.replace(/[#/]/g, '');
         const matchingFolder = privateState.allPersonalFolders.find(f => f.Hash === privateState.routeMatchFolderHash);
         matchingFolder && updateActiveFolderFromRouteMatch(matchingFolder);
      } else {
         updateActiveFolderFromRouteMatch(privateState.personalFolderTree);
      }
   }

   return (
      <Provider value={state}>
         {children}
      </Provider>
   );
};

export {
   useProvider as useFoldersProvider,
   store as foldersStore,
   publicUpdateState as updateFoldersState
};
