import React from 'react';
import {useTranslation} from 'react-i18next';
import DeleteModal from './DeleteModal';
import {ITeamModel} from '../../model/appModel';
import logging from '../../service/logging';
import teamApi from '../../service/teamApi';
import config from '../../service/config';
import {removeTeam} from '../../context/teamsProvider';

export const LeaveTeamModalBase: React.FC<ILeaveTeamModalProps> = ({onClose, onLeave, team}) => {
   const {t} = useTranslation();

   const onDeleteAsync = async () => {
      try {
         await teamApi.leaveTeam(team.TeamId, {userId: config.user.TechSmithId});
         removeTeam(team);
         onLeave();
         onClose();
      } catch (e) {
         logging.error(`Failed to leave team ${team.TeamId} with error: `, e);
      }
   };

   return (
      <DeleteModal
         title={t('team.modal.leave.title')}
         warning={t('team.modal.leave.warning', {teamName: team.Name})}
         onClose={onClose}
         onDelete={onDeleteAsync}
         deleteButtonText={t('team.modal.leave.confirmButtonText')}
      />
   );
};

export interface ILeaveTeamModalProps {
   onClose(): void;
   onLeave(): void;
   team: ITeamModel;
}

export default LeaveTeamModalBase;
