import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {ControlButton, TscButtonType, TscThemeName, TscStyles} from '@techsmith/tsc-cloud-style-guide';
import HamburgerMenuGlyph from '../../static/svg/hamburgerMenu.svg';
import cssConstants from '../../constants/cssConstants';
import {INavigationTab} from '../tabs/Tabs';
import useWindowIsAtOrBelowSize from '../../hooks/useWindowIsAtOrBelowSize';
import Sidebar from '../sidebar/Sidebar';
import config from '../../service/config';

const WrappedHamburgerMenuGlyph = styled(HamburgerMenuGlyph)`
   fill: ${TscStyles.color.text.light};
`;

const MobileNavigationMenuContainer = styled.div<{isHidden: boolean}>`
   transform: translate(0,0);
   transition: transform 0.3s ease 0s;
   position: fixed;
   left: 0;
   bottom: 0;
   height: 100%;
   width: ${cssConstants.mobileNavMenuWidth};
   visibility: ${props => props.isHidden ? 'hidden' : 'visible'};
   z-index: ${cssConstants.zIndex.sidebar};
   box-shadow: 4px 0 10px rgba(0, 0, 0, 0.25);
   ${props => props.isHidden ? 'transform: translate(-100%, 0);' : ''}

   .migration-message {
      margin: 0 4rem .5rem 4rem;
   }
`;

const MobileOverlay = styled.div`
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background: rgba(0,0,0,0.6);
   cursor: pointer;
   transition: opacity 0.3s ease 0s;
   z-index: ${cssConstants.zIndex.sidebar};
`;

const MobileNavMenu: React.FC<IMobileNavMenuProps> = ({tabs}) => {
   const [menuIsHiddenToggled, setMenuIsHiddenToggled] = useState(true);
   const {t} = useTranslation();
   const bodyOverflowStyleRef = useRef(null);
   const headerIsMobileSize = useWindowIsAtOrBelowSize(cssConstants.headerBreakpoint);
   const menuIsHidden = !headerIsMobileSize || menuIsHiddenToggled;
   const displayCreateMenu = config.sidebarShowCreate;

   useEffect(() => {
      if (!menuIsHidden) {
         bodyOverflowStyleRef.current = window.document.body.style.overflow;
         window.document.body.style.overflow = 'hidden';
      } else {
         window.document.body.style.overflow = bodyOverflowStyleRef.current;
      }
   }, [menuIsHidden]);

   const toggleMenuIsHidden = (): void => {
      setMenuIsHiddenToggled(!menuIsHiddenToggled);
   };

   const onMenuContentClick = (e: React.MouseEvent) => {
      if ((e.target as Element).tagName?.toLowerCase() === 'a') {
         setMenuIsHiddenToggled(true);
      }
   };

   return (
      <>
         <ControlButton
            buttonType={TscButtonType.tertiary}
            themeName={TscThemeName.dusk}
            testId="nav-links-hamburger-button"
            glyph={<WrappedHamburgerMenuGlyph />}
            onClick={toggleMenuIsHidden}
            title={t('ToggleMenu')}
         />
         {!menuIsHidden && <MobileOverlay onClick={toggleMenuIsHidden} />}
         <MobileNavigationMenuContainer isHidden={menuIsHidden} id={'mobile-navigation-menu'}>
            <Sidebar displayAddMediaContent={displayCreateMenu} tabs={tabs} menuIsHidden={menuIsHidden} toggleMenuIsHidden={toggleMenuIsHidden} onMenuContentClick={onMenuContentClick} width={cssConstants.mobileNavMenuWidth} />
         </MobileNavigationMenuContainer>
      </>
   );
};

export interface IMobileNavMenuProps {
   tabs: INavigationTab[];
}

export default MobileNavMenu;
